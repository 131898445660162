/* eslint-disable react/prop-types */
import React, {
  useContext, useEffect, useState, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card, Table, Button, Form, InputNumber,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import enums from '../enums';
import collections from '../../../utils/collections';
import { auth, updateDoc } from '../../../utils/firebase';
import { GlobalContext } from '../../../contexts/global';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

const numberConf = {
  controls: false,
  min: 0,
  decimalSeparator: ',',
  style: {
    width: '100%',
  },
};

export default function PricingOthers({ onAddClick }) {
  const { OTHERS, syncOthers } = useContext(GlobalContext);
  const defaultColumns = [{
    title: 'Ürün Adı',
    dataIndex: 'name',
  }, {
    title: 'Birim',
    dataIndex: 'unit',
  }, {
    title: 'Birim Fiyat',
    dataIndex: 'price',
    editable: {
      type: 'INPUT',
    },
  }];

  const EditableContext = React.createContext(null);

  function EditableRow({ index, ...props }) {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  }

  function EditableCell({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave(
          {
            ...record,
            ...values,
          },
          Object.keys(values)[0],
        );
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={required}
        >
          {editable.type === 'INPUT' && (
            <InputNumber
              {...numberConf}
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              style={{
                width: '60px',
                ...editable.style,
              }}
            />
          )}
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  }

  const handleSave = async (row) => {
    await updateDoc(collections.OTHERS, row.id, {
      ...row,
      id: undefined,
    });

    syncOthers();
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = useMemo(() => (
    defaultColumns
      .map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      })
  ), [auth.currentUser]);

  return (
    <Card
      title={`${enums.title.OTHERS} Fiyatları`}
      bordered={false}
      extra={(
        <Button type="primary" icon={React.createElement(PlusOutlined)} onClick={onAddClick} />
      )}
    >
      <Table
        dataSource={OTHERS}
        columns={columns}
        components={components}
        rowKey="id"
        bordered
        pagination={false}
      />
    </Card>
  );
}

PricingOthers.propTypes = {
  onAddClick: PropTypes.func,
};

PricingOthers.defaultProps = {
  onAddClick: () => {},
};
