import React, { useContext, useEffect, useState } from 'react';
import {
  Card, Table, Popconfirm, Button, Select,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import collections from '../../../utils/collections';
import {
  getDocs, queryWhere, where, deleteDoc, and,
} from '../../../utils/firebase';
import { GlobalContext } from '../../../contexts/global';
import { filterOption, roundFloat, formatK } from '../../../utils/common';
import { SUPPLIERS } from '../../../utils/options';
import Page404 from '../../Page404';

const filterSetup = {
  placeholder: 'Seçiniz',
  filterOption,
  showSearch: true,
  allowClear: true,
  mode: 'tags',
};

export default function StockPaper() {
  const columns = [{
    title: 'Tedarikçi Adı',
    dataIndex: 'supplier',
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={SUPPLIERS}
          onChange={(id) => { setSupplier(id); }}
        />
      </div>
    )),
  }, {
    title: 'Malzeme Türü',
    dataIndex: 'material_type',
    render: (value) => getPaper(value)?.name,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={PAPERS}
          onChange={(id) => { setMaterial(id); }}
        />
      </div>
    )),
  }, {
    title: 'Malzeme Kodu',
    dataIndex: 'material_code',
  }, {
    title: 'En',
    dataIndex: 'en',
    render: (value) => formatK(value, 0),
  }, {
    title: 'Boy',
    dataIndex: 'boy',
    render: (value) => formatK(value, 0),
  }, {
    title: 'm2',
    key: 'm2',
    render: (value, item) => formatK(roundFloat((item.en * item.boy) / 1000)),
  }, {
    title: 'Tüketilen Boy',
    dataIndex: 'consumed_boy',
    render: (value) => formatK(value, 0),
  }, {
    title: 'Tüketilen m2',
    key: 'consumed_m2',
    className: 'cell-consumed_m2',
    render: (value, item) => formatK(roundFloat(((item.en * item.boy) / 1000)
      - ((item.en * (item.boy - item.consumed_boy)) / 1000))),
  }, {
    title: 'Kalan Boy',
    key: 'remaining_boy',
    render: (value, item) => formatK((item.boy - item.consumed_boy), 0),
  }, {
    title: 'Kalan m2',
    key: 'remaining_m2',
    className: 'cell-remaining_m2',
    render: (value, item) => formatK(roundFloat((
      (item.en * (item.boy - item.consumed_boy)) / 1000))),
  }, {
    title: '',
    dataIndex: 'id',
    width: 64,
    render: (id) => (
      <Popconfirm
        title="Silmek istediğine emin misin?"
        description="Bu işlem geri alınamaz."
        okText="Evet"
        cancelText="Hayır"
        onConfirm={() => {
          deleteDoc(collections.STOCK_PAPER, id).then(() => {
            getData();
            syncStockPaper();
          });
        }}
      >
        <Button type="primary" danger icon={React.createElement(DeleteOutlined)} />
      </Popconfirm>
    ),
  }];

  const {
    PAPERS, getPaper, syncStockPaper, isAdmin, isUretim, isGrafik,
  } = useContext(GlobalContext);
  const [dataSource, setDataSource] = useState([]);
  const [filterSupplier, setSupplier] = useState('');
  const [filterMaterial, setMaterial] = useState([]);

  async function getData() {
    const w = [];

    if (filterSupplier.length) w.push(where('supplier', 'in', filterSupplier));
    if (filterMaterial.length) w.push(where('material_type', 'in', filterMaterial));

    const query = queryWhere(collections.STOCK_PAPER, and(...w), 'asc', 'en');

    await getDocs(query).then((data) => {
      setDataSource(data);
    });
  }

  useEffect(() => {
    getData();
  }, [filterSupplier, filterMaterial]);

  if (!(isAdmin || isUretim || isGrafik)) {
    return <Page404 />;
  }

  return (
    <Card title="Kağıt Stok Durum" bordered={false}>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        bordered
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: 100,
        }}
      />
    </Card>
  );
}
