import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { GlobalProvider } from '../../contexts/global';

function Print() {
  return (
    <div id="print-root">
      <GlobalProvider>
        <Suspense
          fallback={(
            <div>Loading...</div>
          )}
        >
          <Outlet />
        </Suspense>
      </GlobalProvider>
    </div>
  );
}

export default Print;
