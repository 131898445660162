import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../../utils/routes';
import { GlobalContext } from '../../contexts/global';

export default function Home() {
  const navigate = useNavigate();
  const { isUretim, isGrafik } = useContext(GlobalContext);

  useEffect(() => {
    if (isUretim !== null) {
      if (isUretim) {
        navigate(routes.STOCK_PAPER);
      } else if (isGrafik) {
        navigate(routes.ORDERS);
      } else {
        navigate(routes.OFFERS);
      }
    }
  }, [isUretim]);

  return (
    <p>Redirecting...</p>
  );
}
