import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { GlobalContext } from '../../contexts/global';
import enums from './enums';
import Papers from './components/papers';
import Inks from './components/inks';
import Others from './components/others';
import Add from './components/add';
import Page404 from '../Page404';

export default function Pricing() {
  const [showAdd, setAdd] = useState(enums.NA_EMPTY);
  const { sync, isAdmin } = useContext(GlobalContext);

  if (!isAdmin) {
    return <Page404 />;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} xl={8}>
        <Papers onAddClick={() => { setAdd(enums.PAPERS); }} />
      </Col>
      <Col span={24} xl={8}>
        <Inks onAddClick={() => { setAdd(enums.INKS); }} />
      </Col>
      <Col span={24} xl={8}>
        <Others onAddClick={() => { setAdd(enums.OTHERS); }} />
      </Col>
      <Add
        type={showAdd}
        onClose={(x) => {
          if (x) {
            sync[showAdd]?.();
          }

          setAdd(enums.NA_EMPTY);
        }}
      />
    </Row>
  );
}
