import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, Form, Input, Select, Button, notification,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import services from '../../../services';
import { auth } from '../../../utils/firebase';
import { ROLE } from '../../../utils/options';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

export default function AddUser({ open, onClose }) {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();

  function toClose(x) {
    form.resetFields();
    onClose(x);
  }

  async function onSubmit(values) {
    setLoading(true);

    services.createUser({
      ...values,
      created_by: auth.currentUser.uid,
    }).then(() => {
      toClose(true);

      notificationApi.success({
        message: 'İşlem Başarılı',
      });
    }).catch((error) => {
      const errorCode = error.response.data.code;
      const errorMessage = error.response.data.message;

      notificationApi.error({
        message: errorCode,
        description: errorMessage,
      });
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <Drawer
      title="Kullanıcı Ekle"
      open={open}
      onClose={toClose}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item name="first_name" label="Ad" rules={required}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Soyad" rules={required}>
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[...required, {
            type: 'email',
            message: 'Geçerli bir email adresi giriniz.',
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Şifre" rules={required}>
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Rol" rules={required}>
          <Select placeholder="Seçiniz" options={ROLE} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={React.createElement(SaveOutlined)}
            loading={isLoading}
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </Drawer>
  );
}

AddUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

AddUser.defaultProps = {
  onClose: () => { },
};
