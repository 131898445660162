import React from 'react';
import { Link } from 'react-router-dom';
import {
  UserSwitchOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  AppstoreAddOutlined,
  BankOutlined,
  TeamOutlined,
  DiffOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  LogoutOutlined,
  BuildOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import routes from './routes';
import { auth } from './firebase';

export const common = [];

export const temsilci = [{
  label: 'Müşteriler',
  key: '_CLIENTS',
  children: [{
    label: <Link to={routes.CLIENTS}>Müşteri Listesi</Link>,
    key: 'CLIENTS',
    icon: React.createElement(UserSwitchOutlined),
  }, {
    label: <Link to={routes.CLIENT_CREATE}>Yeni Müşteri Oluştur</Link>,
    key: 'CLIENT_CREATE',
    icon: React.createElement(UsergroupAddOutlined),
  }],
}, {
  label: 'Teklifler',
  key: '_OFFERS',
  children: [{
    label: <Link to={routes.OFFERS}>Tüm Teklifler</Link>,
    key: 'OFFERS',
    icon: React.createElement(UnorderedListOutlined),
  }, {
    label: <Link to={routes.OFFER_CREATE}>Yeni Teklif Oluştur</Link>,
    key: 'OFFER_CREATE',
    icon: React.createElement(AppstoreAddOutlined),
  }],
}];

export const isemri = [{
  label: 'İş Emirleri',
  key: '_ORDERS',
  children: [{
    label: <Link to={routes.ORDERS}>Tüm İş Emirleri</Link>,
    key: 'ORDERS',
    icon: React.createElement(OrderedListOutlined),
  }],
}];

export const uretim = [{
  label: 'Stok',
  key: '_STOCK',
  children: [{
    label: 'Kağıt',
    key: '_STOCK_PAPER',
    children: [{
      label: <Link to={routes.STOCK_PAPER}>Stok Durumu</Link>,
      key: 'STOCK_PAPER',
      icon: React.createElement(DiffOutlined),
    }, {
      label: <Link to={routes.STOCK_PAPER_IN}>Stok Giriş</Link>,
      key: 'STOCK_PAPER_IN',
      icon: React.createElement(FileAddOutlined),
    }, {
      label: <Link to={routes.STOCK_PAPER_OUT}>Stok Çıkış</Link>,
      key: 'STOCK_PAPER_OUT',
      icon: React.createElement(FileDoneOutlined),
    }],
  }],
}];

export const admin = [{
  label: 'Yapılandırma',
  key: '_CONFIG',
  children: [{
    label: <Link to={routes.CONFIG_PRICING}>Fiyat Listesi</Link>,
    key: 'CONFIG_PRICING',
    icon: React.createElement(BankOutlined),
  }, {
    label: <Link to={routes.CONFIG_USERS}>Kullanıcılar</Link>,
    key: 'CONFIG_USERS',
    icon: React.createElement(TeamOutlined),
  }],
}];

export const bottom = [{
  label: `Version: ${process.env.REACT_APP_SHA || 'N/A'}`,
  key: '_VERSION',
  icon: React.createElement(BuildOutlined),
}, {
  label: 'Çıkış Yap',
  key: '_LOGOUT',
  icon: React.createElement(LogoutOutlined),
  onClick: () => {
    auth.signOut(auth);
  },
}];
