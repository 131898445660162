import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, Form, Input, Button, notification,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import services from '../../../services';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

export default function ChangePassword({ open, onClose }) {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();

  function toClose(x) {
    form.resetFields();
    onClose(x);
  }

  async function onSubmit(values) {
    setLoading(true);

    services.changePassword(open, values.password)
      .then(() => {
        toClose(true);

        notificationApi.success({
          message: 'İşlem Başarılı',
        });
      }).catch((error) => {
        const errorCode = error.response.data.code;
        const errorMessage = error.response.data.message;

        notificationApi.error({
          message: errorCode,
          description: errorMessage,
        });
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <Drawer
      title="Şifre Değiştir"
      open={open}
      onClose={toClose}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item name="password" label="Yeni Şifre" rules={required}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={React.createElement(SaveOutlined)}
            loading={isLoading}
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </Drawer>
  );
}

ChangePassword.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

ChangePassword.defaultProps = {
  onClose: () => { },
};
