export default {
  NA_EMPTY: '',
  INKS: 'INKS',
  OTHERS: 'OTHERS',
  PAPERS: 'PAPERS',
  title: {
    INKS: 'Baskı Türü',
    OTHERS: 'Diğer Malzeme',
    PAPERS: 'Kağıt Malzeme',
  },
};
