/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import {
  queryAll, queryWhere, getDocs, auth, where,
} from '../utils/firebase';
import collections from '../utils/collections';

const GlobalContext = React.createContext();

function GlobalProvider({ children }) {
  const [USERS, setUsers] = useState([]);
  const [CLIENTS, setClients] = useState([]);
  const [PAPERS, setPapers] = useState([]);
  const [INKS, setInks] = useState([]);
  const [OTHERS, setOthers] = useState([]);
  const [CONFIG, setConfig] = useState({});
  const [STOCK_PAPER, setStockPaper] = useState([]);
  const [STOCK_PAPER_TYPES, setStockPaperTypes] = useState([]);

  async function getUsers() {
    await getDocs(queryWhere(collections.USERS)).then((data) => {
      setUsers(groupBy(data.map(({
        uid, first_name, last_name, role,
      }) => ({
        value: uid,
        label: `${first_name} ${last_name}`,
        role,
      })), 'role'));
    });
  }

  async function getClients() {
    if (!auth.currentUser) {
      return;
    }

    await getDocs(queryWhere(
      collections.CLIENTS,
      isAdmin
        ? []
        : where('representative_id', '==', auth.currentUser.uid),
    ))
      .then((data) => {
        setClients(data.map(({ id, name, representative_id }) => ({
          value: id,
          label: name,
          representative_id,
        })));
      });
  }

  async function getPapers() {
    await getDocs(queryAll(collections.PAPERS, 'asc')).then((data) => {
      setPapers(data.map((doc) => ({
        ...doc,
        value: doc.id,
        label: doc.name,
      })));
    });
  }

  async function getInks() {
    await getDocs(queryAll(collections.INKS, 'asc')).then((data) => {
      setInks(data.map((doc) => ({
        ...doc,
        value: doc.id,
        label: doc.name,
      })));
    });
  }

  async function getOthers() {
    await getDocs(queryAll(collections.OTHERS, 'asc')).then((data) => {
      setOthers(data);

      const c = {};
      data.forEach(({ id, price }) => { c[id] = price; });
      setConfig(c);
    });
  }

  async function getStockPaper() {
    await getDocs(queryAll(collections.STOCK_PAPER)).then((data) => {
      setStockPaper(data);
    });
  }

  async function getStockPaperTypes() {
    await getDocs(queryAll(collections.STOCK_PAPER_TYPES)).then((data) => {
      setStockPaperTypes(data);
    });
  }

  const stockPaperFilter = useMemo(() => {
    if (!STOCK_PAPER.length) return {};

    const options = {};

    STOCK_PAPER.forEach((item) => {
      /* eslint-disable max-len */
      options[item.supplier] = options[item.supplier] || {};
      options[item.supplier][item.material_type] = options[item.supplier][item.material_type] || {};
      options[item.supplier][item.material_type][item.material_code] = options[item.supplier][item.material_type][item.material_code] || {};
      options[item.supplier][item.material_type][item.material_code][item.en] = options[item.supplier][item.material_type][item.material_code][item.en] || [];
      options[item.supplier][item.material_type][item.material_code][item.en].push(item);
      /* eslint-enable */
    });

    return options;
  }, [STOCK_PAPER]);

  useEffect(() => {
    getUsers();
    getPapers();
    getInks();
    getOthers();
    getStockPaper();
  }, []);

  function getClient(id) {
    return CLIENTS.find((c) => c.value === id) || {};
  }

  function getUserFullName(uid) {
    return Object.values(USERS).flat().find((u) => u.value === uid)?.label;
  }

  function getPaper(id) {
    return PAPERS.find((p) => p.value === id) || {};
  }

  function getInk(id) {
    return INKS.find((i) => i.value === id) || {};
  }

  const [isAdmin, setIsAdmin] = useState(null);
  const [isTemsilci, setIsTemsilci] = useState(null);
  const [isUretim, setIsUretim] = useState(null);
  const [isGrafik, setIsGrafik] = useState(null);

  async function setUserRole() {
    if (auth.currentUser) {
      const query = queryWhere(collections.USERS, where('uid', '==', auth.currentUser.uid));

      await getDocs(query).then((data) => {
        setIsAdmin(data[0].role === 'ADMIN');
        setIsTemsilci(data[0].role === 'TEMSILCI');
        setIsUretim(data[0].role === 'URETIM');
        setIsGrafik(data[0].role === 'GRAFIK');
      });
    }
  }

  useEffect(() => {
    getClients();
    setUserRole();
  }, [auth.currentUser, isAdmin]);

  return (
    <GlobalContext.Provider
      value={{
        CLIENTS,
        syncClients: getClients,
        getClient,
        USERS,
        syncUsers: getUsers,
        getUserFullName,
        PAPERS,
        syncPapers: getPapers,
        getPaper,
        INKS,
        syncInks: getInks,
        getInk,
        syncOthers: getOthers,
        OTHERS,
        STOCK_PAPER,
        syncStockPaper: getStockPaper,
        stockPaperFilter,
        STOCK_PAPER_TYPES,
        syncStockPaperTypes: getStockPaperTypes,
        CONFIG,
        sync: {
          CLIENTS: getClients,
          PAPERS: getPapers,
          INKS: getInks,
          OTHERS: getOthers,
        },
        isAdmin,
        isTemsilci,
        isUretim,
        isGrafik,
        currentUser: auth.currentUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalProvider };

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
