export const YV = [{
  value: 'Yok',
  label: 'Yok',
}, {
  value: 'Var',
  label: 'Var',
}];

export const SET_ASSORTMENT = [{
  value: 'Yok',
  label: 'Yok',
}, {
  value: 'Takım',
  label: 'Takım',
}, {
  value: 'Çeşit',
  label: 'Çeşit',
}, {
  value: 'Takım ve Çeşit',
  label: 'Takım ve Çeşit',
}];

export const LAK = [{
  value: 'Yok',
  label: 'Yok',
}, {
  value: 'Parlak',
  label: 'Parlak',
}, {
  value: 'Mat',
  label: 'Mat',
}, {
  value: 'Ribon',
  label: 'Ribon',
}];

export const SELOFAN = [{
  value: 'Yok',
  label: 'Yok',
}, {
  value: 'Mat',
  label: 'Mat',
}, {
  value: 'Soft Touch',
  label: 'Soft Touch',
}];

export const YALDIZ = [{
  value: 'Yok',
  label: 'Yok',
}, {
  value: 'Altın',
  label: 'Altın',
}, {
  value: 'Gümüş',
  label: 'Gümüş',
}, {
  value: 'Özel',
  label: 'Özel',
}];

export const YALDIZ_TYPE = [{
  value: '--',
  label: '--',
}, {
  value: 'Sıcak',
  label: 'Sıcak',
}, {
  value: 'Soğuk',
  label: 'Soğuk',
}];

export const STATUS = [{
  value: 'Teklif Gönderildi',
  label: 'Teklif Gönderildi',
}, {
  value: 'Teklif Kazanıldı',
  label: 'Teklif Kazanıldı',
}, {
  value: 'Teklif Reddedildi',
  label: 'Teklif Reddedildi',
}];

export const UNIT = [{
  value: '--',
  label: '--',
}, {
  value: 'Adet',
  label: 'Adet',
}, {
  value: '€',
  label: '€',
}, {
  value: '€ / m2',
  label: '€ / m2',
}, {
  value: '€ / Renk Adet',
  label: '€ / Renk Adet',
}, {
  value: '€ / Etiket Adet',
  label: '€ / Etiket Adet',
}, {
  value: '€ / Kalıp Başına',
  label: '€ / Kalıp Başına',
}];

export const ROLE = [{
  value: 'ADMIN',
  label: 'ADMIN',
}, {
  value: 'TEMSILCI',
  label: 'TEMSILCI',
}, {
  value: 'URETIM',
  label: 'URETIM',
}, {
  value: 'GRAFIK',
  label: 'GRAFIK',
}];

export const SUPPLIERS = [{
  value: 'FIN',
  label: 'FIN',
}, {
  value: 'Fasson',
  label: 'Fasson',
}];

export const ORDER_IS = [{
  value: 'Yeni',
  label: 'Yeni',
}, {
  value: 'Tekrar',
  label: 'Tekrar',
}, {
  value: 'Revize',
  label: 'Revize',
}];

export const ORDER_STATUS = [{
  value: 0,
  label: 'Teklif Onaylandı',
  title: 'Teklif Onaylandı',
}, {
  value: 1,
  label: 'Baskı Onayı Alındı',
  title: 'Baskı Onayı Alındı',
}, {
  value: 2,
  label: 'Üretim Onayı Alındı',
  title: 'Üretim Onayı Alındı',
}, {
  value: 3,
  label: 'Üretim Tamamlandı',
  title: 'Üretim Tamamlandı',
}];

export const ORDER_PRINT_TYPES = [{
  value: 'Dijital Xeikon',
  label: 'Dijital Xeikon',
}, {
  value: 'Dijital Screen',
  label: 'Dijital Screen',
}, {
  value: 'Flekso',
  label: 'Flekso',
}, {
  value: 'Offset',
  label: 'Offset',
}, {
  value: 'Baskısız',
  label: 'Baskısız',
}];

export const YOK = 'Yok';
