import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, Form, Input, InputNumber, Button, Select,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import collections from '../../../utils/collections';
import { addDoc } from '../../../utils/firebase';
import { UNIT } from '../../../utils/options';
import enums from '../enums';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];
const numberConf = {
  controls: false,
  min: 0,
  decimalSeparator: ',',
  style: {
    width: '100%',
  },
};

export default function Add({ type, onClose }) {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  function toClose(x) {
    form.resetFields();
    onClose(x);
  }

  async function onSubmit(values) {
    setLoading(true);
    await addDoc(collections[type], values);
    setLoading(false);
    toClose(true);
  }

  return (
    <Drawer
      title={`${enums.title[type]} Ekle`}
      open={!!type}
      onClose={toClose}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item name="name" label="Ürün Adı" rules={required}>
          <Input />
        </Form.Item>
        {type === enums.OTHERS && (
          <Form.Item name="unit" label="Birim" rules={required}>
            <Select placeholder="Seçiniz" options={UNIT} />
          </Form.Item>
        )}
        <Form.Item name="price" label="Fiyat" rules={required}>
          <InputNumber {...numberConf} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={React.createElement(SaveOutlined)}
            loading={isLoading}
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

Add.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

Add.defaultProps = {
  onClose: () => { },
};
