import React from 'react';
import { Link } from 'react-router-dom';

export default function Page404() {
  return (
    <div style={{ padding: 20 }}>
      <h1>Sayfa Bulunamadı</h1>
      <p>Aradığınız sayfa mevcut değil.</p>
      <Link to="/">Ana sayfa'ya geri dön</Link>
    </div>
  );
}
