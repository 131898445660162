import { YALDIZ_TYPE, STATUS } from './options';
import { roundFloat } from './common';

const DEFAULT_FLEKSO_ID = process.env.REACT_APP_DEFAULT_FLEKSO_ID;
const PAPER_SALES_VALUE = process.env.REACT_APP_CALC_PAPER_SALES_VALUE;
// const KLISE_BEDELI = process.env.REACT_APP_CALC_KLISE_BEDELI;
const EMBOSS_LAK = process.env.REACT_APP_CALC_EMBOSS_LAK;
const EMBOSS_KALIP_BEDELI = process.env.REACT_APP_CALC_EMBOSS_KALIP_BEDELI;
const YALDIZ_BEDELI = process.env.REACT_APP_CALC_YALDIZ_BEDELI;
const MAT_SELOFON = process.env.REACT_APP_CALC_MAT_SELOFON;
const SOFT_TOUCH_SELOFON = process.env.REACT_APP_CALC_SOFT_TOUCH_SELOFON;
const BICAK_VAR = process.env.REACT_APP_CALC_BICAK_VAR;
const BICAK_YOK = process.env.REACT_APP_CALC_BICAK_YOK;
const SICAK_YALDIZ = process.env.REACT_APP_CALC_SICAK_YALDIZ;
const SOGUK_YALDIZ = process.env.REACT_APP_CALC_SOGUK_YALDIZ;

const noCalculateTriggerFields = ['name', 'status'];
const calculatedAndEditableValues = ['emboss_kalip_bedeli', 'boy_fire', 'toplam_fiyat', 'selofan_bedeli', 'yaldiz_bedeli', 'yaldiz_type', 'klise_bedeli'];

export function setOffer(config, data, field) {
  const row = {
    ...data,
  };

  if (noCalculateTriggerFields.includes(field)) {
    return row;
  }

  if (!data.yan_yana) {
    row.yan_yana = 4;
  }

  if (!data.paper_sales_value) {
    row.paper_sales_value = config[PAPER_SALES_VALUE];
  }

  row.kagit_satis = roundFloat(row.material_price * (1 + (row.paper_sales_value / 100)));

  row.bobin_en = row.yan_yana
    ? roundFloat(((data.en * row.yan_yana) + ((row.yan_yana - 1) * 4) + 20) / 1000, 3)
    : 0;

  row.bobin_boy = row.yan_yana
    ? roundFloat(((data.boy + 3) * (data.total_piece / row.yan_yana)) / 1000)
    : 0;

  if (field === 'ink'
    || field === 'lak'
    || field === 'selofan'
    || field === 'emboss'
    || field === 'yaldiz'
    || !data.boy_fire
  ) {
    if (row.ink === DEFAULT_FLEKSO_ID) {
      const limit = 3000;
      let times = 1;

      for (let i = 0; i < Math.ceil(row.bobin_boy / limit); i += 1) {
        times += 1;
      }

      row.boy_fire = 50 + (times * 50);
    } else if (
      data.lak === 'Yok'
      && data.selofan === 'Yok'
      && data.emboss === 'Yok'
      && data.yaldiz === 'Yok'
    ) {
      row.boy_fire = 60;
    } else {
      row.boy_fire = 90;
    }
  }

  row.toplam_boy = roundFloat(row.bobin_boy + row.boy_fire);
  row.metrekare = roundFloat(row.bobin_en * row.toplam_boy);

  row.kagit_fiyat_toplam = roundFloat(row.metrekare * row.kagit_satis);
  row.murekkep_fiyat_toplam = roundFloat(row.metrekare * row.ink_price);

  if (!data.bicak_fiyat) {
    row.bicak_fiyat = data.bicak === 'Var' ? config[BICAK_VAR] : config[BICAK_YOK];
  }

  row.ara_toplam = roundFloat(row.kagit_fiyat_toplam + row.murekkep_fiyat_toplam + row.bicak_fiyat);
  row.ara_toplam_170 = roundFloat(row.ara_toplam * 1.70);
  row.ara_toplam_160 = roundFloat(row.ara_toplam * 1.60);
  row.ara_toplam_150 = roundFloat(row.ara_toplam * 1.50);
  row.ara_toplam_140 = roundFloat(row.ara_toplam * 1.40);
  row.ara_toplam_130 = roundFloat(row.ara_toplam * 1.30);

  if (!data.klise_bedeli || field === 'ink') {
    // row.klise_bedeli = row.ink === DEFAULT_FLEKSO_ID ? config[KLISE_BEDELI] : 0;
    row.klise_bedeli = 0;
  }

  if (field === 'emboss' || data.emboss_kalip_bedeli === undefined) {
    row.emboss_kalip_bedeli = data.emboss === 'Var' ? config[EMBOSS_KALIP_BEDELI] : 0;
  }

  if (data.emboss === 'Yok') {
    row.emboss_bedeli = 0;
  } else {
    row.emboss_bedeli = row.yan_yana ? roundFloat(
      row.emboss_kalip_bedeli + ((data.total_piece / row.yan_yana) * config[EMBOSS_LAK]),
    ) : 0;
  }

  if (!data.yaldiz_bedeli) {
    row.yaldiz_bedeli = 0;
  }

  if (field === 'yaldiz' || !data.yaldiz_klise) {
    row.yaldiz_klise = data.yaldiz !== 'Yok' ? config[YALDIZ_BEDELI] : 0;
  }

  if (!data.yaldiz_type || data.yaldiz === 'Yok') {
    row.yaldiz_type = YALDIZ_TYPE[0].value;
  }

  if (field === 'yaldiz' && data.yaldiz === 'Yok') {
    row.yaldiz_bedeli = 0;
    row.yaldiz_type = YALDIZ_TYPE[0].value;
  }

  if (!field || (field === 'yaldiz' || field === 'yaldiz_type' || field === 'total_piece'
    || field === 'yan_yana' || field === 'boy_fire' || field === 'en' || field === 'boy')) {
    if (data.yaldiz_type === 'Sıcak' && data.yaldiz !== 'Yok') {
      if (row.metrekare <= 50) {
        row.yaldiz_bedeli = roundFloat((row.metrekare * 1.15) * config[SICAK_YALDIZ]);
      } else {
        row.yaldiz_bedeli = roundFloat((row.metrekare * 1.10) * config[SICAK_YALDIZ]);
      }
    } else if (data.yaldiz_type === 'Soğuk' && data.yaldiz !== 'Yok') {
      if (row.metrekare <= 50) {
        row.yaldiz_bedeli = roundFloat((row.metrekare * 1.15) * config[SOGUK_YALDIZ]);
      } else {
        row.yaldiz_bedeli = roundFloat((row.metrekare * 1.10) * config[SOGUK_YALDIZ]);
      }
    } else {
      row.yaldiz_bedeli = 0;
    }
  }

  if (field === 'selofan' || field === 'boy' || field === 'boy_fire'
    || field === 'en' || field === 'total_piece' || field === 'yan_yana' || !data.selofan_bedeli) {
    row.selofan_bedeli = 0;

    if (data.selofan === 'Mat') {
      if (row.metrekare <= 50) {
        row.selofan_bedeli = roundFloat((row.metrekare * 1.15) * config[MAT_SELOFON]);
      } else {
        row.selofan_bedeli = roundFloat((row.metrekare * 1.10) * config[MAT_SELOFON]);
      }
    }

    if (data.selofan === 'Soft Touch') {
      if (row.metrekare <= 50) {
        row.selofan_bedeli = roundFloat((row.metrekare * 1.15) * config[SOFT_TOUCH_SELOFON]);
      } else {
        row.selofan_bedeli = roundFloat((row.metrekare * 1.10) * config[SOFT_TOUCH_SELOFON]);
      }
    }
  }

  if (!data.status) {
    row.status = STATUS[0].value;
  }

  row.toplam_fiyat = roundFloat(row.ara_toplam_170 + row.klise_bedeli + row.emboss_bedeli
    + row.yaldiz_bedeli + row.yaldiz_klise + row.selofan_bedeli);

  if (!data.teklif_fiyat) {
    row.teklif_fiyat = 0;
  }

  if (calculatedAndEditableValues.includes(field)) {
    row[field] = data[field];
  }

  return row;
}

export const version = '1.0.0';
