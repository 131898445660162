import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.env = process.env.REACT_APP_ENV;
axios.interceptors.response.use((response) => (response.data), (error) => (Promise.reject(error)));

export default {
  createUser(body) {
    return axios.post('/users', body);
  },
  deleteUser(body) {
    return axios.put('/users', body);
  },
  changePassword(uid, password) {
    return axios.put(`/users/${uid}`, { password });
  },
};
