/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import {
  Row, Col, Card, Form, Button, InputNumber, Select, notification,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import collections from '../../../utils/collections';
import { addDoc, updateDoc } from '../../../utils/firebase';
import { GlobalContext } from '../../../contexts/global';
import { filterOption } from '../../../utils/common';
import Page404 from '../../Page404';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];
const numberConf = {
  controls: false,
  min: 1,
  decimalSeparator: ',',
  precision: 0,
  style: {
    width: '100%',
  },
};

export default function StockPaperOut() {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const {
    stockPaperFilter, syncStockPaper, getPaper, isAdmin, isUretim, isGrafik,
  } = useContext(GlobalContext);

  useEffect(() => {
    syncStockPaper();
  }, []);

  const [fSupplier, setSupplier] = useState(null);
  const [fType, setType] = useState(null);
  const [fCode, setCode] = useState(null);
  const [fEn, setEn] = useState(null);
  const [fBobin, setBobin] = useState(null);

  async function onSubmit(values) {
    setLoading(true);

    const bobins = stockPaperFilter?.[fSupplier]?.[fType]?.[fCode]?.[fEn];
    const bobin = bobins.find(b => b.id === fBobin);

    if (bobin) {
      const next = (bobin.consumed_boy + values.boy);

      if (!(bobin.boy - next >= 0)) {
        notificationApi.error({
          message: 'Hata',
          description: 'Girilen miktarda stok kalmamıştır.',
        });

        setLoading(false);
        return;
      }

      await updateDoc(collections.STOCK_PAPER, bobin.id, {
        ...bobin,
        id: undefined,
        consumed_boy: next,
      });

      await addDoc(collections.STOCK_PAPER_OUT, {
        ...bobin,
        id: undefined,
        original_id: bobin.id,
        out: values.boy,
        next_consumed_boy: next,
        via: 'STOCK_OUT',
      });

      syncStockPaper();
      form.resetFields();
      notificationApi.success({
        message: 'İşlem Başarılı',
        description: 'Kağıt stok çıkış işlemi gerçekleşti.',
      });
    } else {
      notificationApi.error({
        message: 'Hata',
        description: 'Bobin bulunamadı.',
      });
    }

    setLoading(false);
  }

  function renderOptions(key) {
    if (Array.isArray(key) && typeof key[0] === 'number') {
      return key.map((item) => ({ value: item, label: item }));
    }

    if (Array.isArray(key) && typeof key[0] === 'object') {
      return key.map((k, index) => ({ label: `Bobin ${index + 1} - Kalan Boy: ${k.boy - k.consumed_boy}`, value: k.id }));
    }

    return Object.keys(key || {}).map((item) => ({ value: item, label: item }));
  }

  if (!(isAdmin || isUretim || isGrafik)) {
    return <Page404 />;
  }

  return (
    <Row>
      <Col lg={24} xl={12} xxl={10}>
        <Card title="Kağıt Stok Çıkış" bordered={false}>
          <Form
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              name="supplier"
              label="Tedarikçi Adı"
              rules={required}
            >
              <Select
                placeholder="Seçiniz"
                options={renderOptions(stockPaperFilter)}
                onChange={(value) => {
                  setSupplier(value);
                  form.resetFields(['material_type', 'material_code', 'en', 'boy', 'bobin']);
                }}
                showSearch
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item
              name="material_type"
              label="Malzeme Türü"
              rules={required}
            >
              <Select
                placeholder="Seçiniz"
                options={renderOptions(stockPaperFilter?.[fSupplier]).map((p) => ({
                  label: getPaper(p.value)?.label,
                  value: p.value,
                }))}
                onChange={(value) => {
                  setType(value);
                  form.resetFields(['material_code', 'en', 'boy', 'bobin']);
                }}
              />
            </Form.Item>
            <Form.Item
              name="material_code"
              label="Malzeme Kodu"
              rules={required}
            >
              <Select
                placeholder="Seçiniz"
                options={renderOptions(stockPaperFilter?.[fSupplier]?.[fType])}
                onChange={(value) => {
                  setCode(value);
                  form.resetFields(['en', 'boy', 'bobin']);
                }}
              />
            </Form.Item>
            <Form.Item
              name="en"
              label="En"
              rules={required}
            >
              <Select
                placeholder="Seçiniz"
                options={renderOptions(stockPaperFilter?.[fSupplier]?.[fType]?.[fCode])}
                onChange={(value) => {
                  setEn(value);
                  form.resetFields(['boy', 'bobin']);
                }}
              />
            </Form.Item>
            <Form.Item
              name="bobin"
              label="Bobin"
              rules={required}
            >
              <Select
                placeholder="Seçiniz"
                options={renderOptions(stockPaperFilter?.[fSupplier]?.[fType]?.[fCode]?.[fEn])}
                onChange={(value) => {
                  setBobin(value);
                  form.resetFields(['boy']);
                }}
              />
            </Form.Item>
            <Form.Item
              name="boy"
              label="Kullanılan Boy"
              rules={required}
            >
              <InputNumber {...numberConf} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                icon={React.createElement(SaveOutlined)}
                loading={isLoading}
              >
                Kaydet
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      {contextHolder}
    </Row>
  );
}
