import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form, Input, Button, Card, notification, message,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../utils/firebase';
import routes from '../utils/routes';

const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];

export default function Login() {
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [messageApi, contextMessageHolder] = message.useMessage();

  const onFinish = (values) => {
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Giriş Başarılı',
        });

        setTimeout(() => {
          navigate(routes.HOME);
        }, 300);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        notificationApi.error({
          message: errorCode,
          description: errorMessage,
        });
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card style={{ width: 500 }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 12 }}>
          <img src="/logo.png" alt="Asal Etiket Logo" />
        </div>
        <Form name="normal_login" className="login-form" onFinish={onFinish}>
          <Form.Item name="email" rules={required}>
            <Input
              placeholder="E-posta"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item name="password" rules={required}>
            <Input
              type="password"
              placeholder="Şifre"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              Giriş Yap
            </Button>
          </Form.Item>
        </Form>
      </Card>
      {contextHolder}
      {contextMessageHolder}
    </div>
  );
}
