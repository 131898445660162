import React, { useContext, useEffect, useState } from 'react';
import {
  Card, Table, Button, Space, Popconfirm, notification, Select, Input,
} from 'antd';
import { Link } from 'react-router-dom';
import { DoubleRightOutlined, DeleteOutlined } from '@ant-design/icons';
import routes from '../../utils/routes';
import collections from '../../utils/collections';
import {
  getDocs, queryWhere, deleteDoc, auth, where, and,
} from '../../utils/firebase';
import { GlobalContext } from '../../contexts/global';
import { ORDER_STATUS, ORDER_PRINT_TYPES } from '../../utils/options';
import { formatDate, filterOption } from '../../utils/common';
import Page404 from '../Page404';

const DATE_FORMAT = 'DD/MM/YYYY';
const config = {
  okText: 'Evet',
  cancelText: 'Hayır',
};
const filterSetup = {
  placeholder: 'Seçiniz',
  filterOption,
  showSearch: true,
  allowClear: true,
  mode: 'tags',
};

function sortHelper(a, b, key) {
  return a[key] - b[key];
}

export default function Orders() {
  const {
    getUserFullName, getPaper, CLIENTS, USERS, PAPERS, isAdmin, isTemsilci, isUretim, isGrafik,
  } = useContext(GlobalContext);
  const [dataSource, setDataSource] = useState([]);
  const [notFilteredDataSource, setNFDS] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [filterClient, setClient] = useState([]);
  const [filterName, setName] = useState('');
  const [filterRepresentative, setRepresentative] = useState([]);
  const [filterGrapher, setGrapher] = useState([]);
  const [filterPrintType, setPrintType] = useState([]);
  const [filterMaterial, setMaterial] = useState([]);
  const [filterStatus, setStatus] = useState([]);

  const columns = [{
    title: 'Firma Ticaret Ünvanı',
    dataIndex: ['offer', 'client_name'],
    isCommon: true,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={CLIENTS}
          onChange={(id) => { setClient(id); }}
        />
      </div>
    )),
  }, {
    title: 'İşin Adı',
    dataIndex: ['offer', 'name'],
    isCommon: true,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Input
          allowClear
          onPressEnter={({ target }) => { setName(target.value); }}
          onBlur={({ target }) => { setName(target.value); }}
        />
      </div>
    )),
  }, {
    title: 'Satış Temsilcisi',
    dataIndex: ['offer', 'client_representative'],
    isCommon: false,
    render: (id) => getUserFullName(id),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={USERS.TEMSILCI}
          onChange={(id) => { setRepresentative(id); }}
        />
      </div>
    )),
  }, {
    title: 'Grafiker',
    dataIndex: 'grapher',
    isCommon: true,
    render: (id) => getUserFullName(id),
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={USERS.GRAFIK}
          onChange={(id) => { setGrapher(id); }}
        />
      </div>
    )),
  }, {
    title: 'Sipariş Tarihi',
    dataIndex: 'order_date',
    isCommon: true,
    render: (date) => formatDate(date, DATE_FORMAT),
    sorter: (a, b) => sortHelper(a, b, 'order_date'),
  }, {
    title: 'Baskı Onay Tarihi',
    dataIndex: 'print_approval_date',
    isCommon: true,
    render: (date) => formatDate(date, DATE_FORMAT),
    sorter: (a, b) => sortHelper(a, b, 'print_approval_date'),
  }, {
    title: 'Üretim Baslangıç Tarihi',
    dataIndex: 'production_start_date',
    isCommon: true,
    render: (date) => formatDate(date, DATE_FORMAT),
    sorter: (a, b) => sortHelper(a, b, 'production_start_date'),
  }, {
    title: 'Baskı Türü',
    dataIndex: 'print_type',
    isCommon: true,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={ORDER_PRINT_TYPES}
          onChange={(id) => { setPrintType(id); }}
        />
      </div>
    )),
  }, {
    title: 'Kağıt Türü',
    dataIndex: ['offer', 'material'],
    isCommon: true,
    render: (value) => getPaper(value)?.name,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={PAPERS}
          onChange={(id) => { setMaterial(id); }}
        />
      </div>
    )),
  }, {
    title: 'Durum',
    dataIndex: 'status',
    isCommon: true,
    render: (id) => ORDER_STATUS[id]?.label,
    filterDropdown: (() => (
      <div className="table-filter-wrapper">
        <Select
          {...filterSetup}
          options={ORDER_STATUS}
          onChange={(id) => { setStatus(id); }}
        />
      </div>
    )),
  }, {
    title: '',
    dataIndex: 'id',
    isCommon: true,
    render: (orderId) => (
      <Space>
        <Button type="primary" className="link-like-button">
          <Link to={routes.ORDER_DETAIL.replace(':orderId', orderId)}>
            {React.createElement(DoubleRightOutlined)}
          </Link>
        </Button>
        {(isAdmin || isTemsilci) && (
          <Popconfirm
            title="Silmek istediğine emin misin?"
            okText={config.okText}
            cancelText={config.cancelText}
            onConfirm={() => deleteOrder(orderId)}
          >
            <Button type="primary" danger icon={React.createElement(DeleteOutlined)} />
          </Popconfirm>
        )}
      </Space>
    ),
  }];

  async function getData() {
    const w = [];

    if (!auth.currentUser) {
      return;
    }

    if (!(isAdmin || isGrafik)) w.push(where('created_by', '==', auth.currentUser.uid));
    if (filterClient.length) w.push(where('offer.client_id', 'in', filterClient));
    if (filterRepresentative.length) w.push(where('offer.client_representative', 'in', filterRepresentative));
    if (filterGrapher.length) w.push(where('grapher', 'in', filterGrapher));
    if (filterPrintType.length) w.push(where('print_type', 'in', filterPrintType));
    if (filterMaterial.length) w.push(where('offer.material', 'in', filterMaterial));
    if (filterStatus.length) w.push(where('status', 'in', filterStatus));

    const query = queryWhere(collections.ORDERS, and(...w));

    await getDocs(query).then((data) => {
      setDataSource(data);
      setNFDS(data);
    });
  }

  useEffect(() => {
    getData();
  }, [isAdmin, filterClient, filterRepresentative,
    filterGrapher, filterPrintType, filterMaterial, filterStatus]);

  function onSuccess(description = '') {
    notificationApi.success({
      message: 'İşlem Başarılı',
      description,
    });
  }

  async function deleteOrder(id) {
    await deleteDoc(collections.ORDERS, id);
    onSuccess('İş emri silindi.');
    getData();
  }

  useEffect(() => {
    if (filterName?.length) {
      const filtered = notFilteredDataSource
        .filter((row) => row.offer.name.toLowerCase('tr').includes(filterName.toLowerCase('tr')));
      setDataSource(filtered);
    } else if (notFilteredDataSource.length !== dataSource.length) {
      setDataSource(notFilteredDataSource);
    }
  }, [filterName, notFilteredDataSource]);

  if (!(isAdmin || isTemsilci || isUretim || isGrafik)) {
    return <Page404 />;
  }

  return (
    <Card title="Tüm İş Emirleri" bordered={false}>
      <Table
        dataSource={dataSource}
        columns={columns.filter((col) => (col.isCommon || isAdmin))}
        rowKey="id"
        bordered
        pagination={{
          defaultPageSize: 20,
        }}
        rowClassName={(record) => `order-status-${record.status}`}
      />
      {contextHolder}
    </Card>
  );
}
