import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export default function Delete({ onConfirm }) {
  return (
    <Popconfirm
      title="Silmek istediğine emin misin?"
      description="Bu işlem geri alınamaz."
      okText="Evet"
      cancelText="Hayır"
      onConfirm={onConfirm}
    >
      <Button type="primary" danger icon={React.createElement(DeleteOutlined)} />
    </Popconfirm>
  );
}

Delete.propTypes = {
  onConfirm: PropTypes.func,
};

Delete.defaultProps = {
  onConfirm: () => { },
};
