import React, { useEffect, useState, useContext } from 'react';
import {
  Card, Table, Button, Space, Popconfirm,
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import services from '../../services';
import collections from '../../utils/collections';
import { queryWhere, getDocs } from '../../utils/firebase';
import { GlobalContext } from '../../contexts/global';
import AddUser from './components/addUser';
import ChangePassword from './components/changePassword';
import Page404 from '../Page404';

export default function Users() {
  const columns = [{
    title: 'Rol',
    dataIndex: 'role',
  }, {
    title: 'Ad',
    dataIndex: 'first_name',
  }, {
    title: 'Soyad',
    dataIndex: 'last_name',
  }, {
    title: 'Email',
    dataIndex: 'email',
  }, {
    title: '',
    dataIndex: 'uid',
    width: 72,
    render: (uid, item) => (
      <Space>
        <Button
          danger
          type="dashed"
          icon={React.createElement(EditOutlined)}
          onClick={() => { setChange(uid); }}
        />
        <Popconfirm
          title="Silmek istediğine emin misin?"
          description="Bu işlem geri alınamaz."
          okText="Evet"
          cancelText="Hayır"
          onConfirm={() => {
            services.deleteUser({
              docId: item.id,
              uid,
            })
              .then(() => {
                getData();
                syncUsers();
              });
          }}
        >
          <Button
            danger
            type="primary"
            icon={React.createElement(DeleteOutlined)}
          />
        </Popconfirm>
      </Space>
    ),
  }];

  const [isLoading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showAdd, setAdd] = useState(false);
  const [showChange, setChange] = useState(false);

  async function getData() {
    setLoading(true);

    await getDocs(queryWhere(collections.USERS)).then((data) => {
      setDataSource(data);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  const { isAdmin, syncUsers } = useContext(GlobalContext);

  if (!isAdmin) {
    return <Page404 />;
  }

  return (
    <Card
      title="Kullanıcılar"
      bordered={false}
      extra={(
        <Button type="primary" icon={React.createElement(PlusOutlined)} onClick={() => { setAdd(true); }} />
      )}
    >
      <Table
        loading={isLoading}
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        bordered
        pagination={false}
      />
      <AddUser
        open={showAdd}
        onClose={(x) => {
          if (x) {
            getData();
            syncUsers();
          }

          setAdd(false);
        }}
      />
      <ChangePassword
        open={showChange}
        onClose={() => {
          setChange(false);
        }}
      />
    </Card>
  );
}
