import React, { Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { clarity } from 'react-microsoft-clarity';
import routes from './utils/routes';
import { auth } from './utils/firebase';
import { GlobalProvider } from './contexts/global';
import Menu from './components/menu';

const { Content, Footer } = Layout;
const CLARITY_ID = process.env.REACT_APP_CLARITY_ID;

if (CLARITY_ID) {
  clarity.init(CLARITY_ID);
}

function App() {
  const navigate = useNavigate();

  auth.onAuthStateChanged(() => {
    if (!auth.currentUser) {
      navigate(routes.LOGIN);
    } else if (clarity.hasStarted()) {
      clarity.identify('USER_ID', auth.currentUser.email);
    }
  });

  return (
    <div id="app-root">
      <GlobalProvider>
        <Layout hasSider>
          <Menu />
          <Layout
            style={{
              minHeight: '100vh',
            }}
          >
            <Content
              style={{
                margin: '24px 16px 0',
                overflow: 'initial',
              }}
            >
              <Suspense
                fallback={(
                  <div>Loading...</div>
                )}
              >
                <Outlet />
              </Suspense>
            </Content>
            <Footer
              style={{
                padding: '0',
                lineHeight: '48px',
                textAlign: 'center',
              }}
            >
              Asal Etiket Web App ©
              {new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </GlobalProvider>
    </div>
  );
}

export default App;
